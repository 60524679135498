(function ($) {
    // Create checkin
    $('#form-create-menu').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/menu');
                });
            }
        }
    });
    $(document).on('click', '.btn-comment-menu', function (e) {
        e.preventDefault();
        if($('#form-comment-menu').valid() == true) {
            $('#btn-comment-menu').attr('disabled', 'disabled');
            $('#form-comment-menu').submit();
        }
    });
    // Create comment
    $('#form-comment-menu').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            $('content_comment').attr('disabled', 'disabled');
            console.log(res);
            var href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $.notify({
                    message: res.error
                },{
                    type: 'meclip',
                    delay: 3000,
                    timer: 500,
                    placement: {
                        from: 'top',
                        align: 'right'
                    },
                });
                $('#btn-comment-menu').removeAttr('disabled', 'disabled');
            }
            else {
                $('#content_comment').val('');
                $('<div class="comment-detail form-group">' +
                    '<img src="'+ res.avatar +'" alt="Avatar">' +
                    '<div class="text">' +
                    '<p class="m-0 name">'+ res.name +'</p>' +
                    '<small class="time">'+ res.now +'</small>' +
                    '<p class="content">'+ res.comment +'</p>' +
                    '</div></div>').prependTo('.m-comment-new');
                $('#btn-comment-menu').removeAttr('disabled', 'disabled');
                $.notify({
                    message: res.success
                },{
                    type: 'meclip',
                    delay: 3000,
                    timer: 500,
                    placement: {
                        from: 'top',
                        align: 'right'
                    },
                });
            }
        }
    });
    // Edit menu
    $('#form-edit-menu').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // click button edit menu
    $(document).on('click', '.btn-edit-menu', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var date = $(this).attr('data-date');
        var content = $(this).attr('data-content');

        $('input[name="id"]').val(id);
        $('#edit_menu_date').val(date);
        $('.menu-edit-editor-content .note-editor.note-frame .note-editable').html(content);

        $('#edit-menu').modal('show');
    });

    // Delete menu
    $(document).on('click', '.btn-delete-menu', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Menu',
            text: 'Bạn muốn xóa thực đơn?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/menu/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click detail menu
    $(document).on('click', '.btn-detail-menu', function (e) {
        e.preventDefault();

        var date = $(this).attr('data-date');
        var content = $(this).attr('data-content');
        var img = $(this).attr('data-img');
        if (img.length == 0){
            img = BASE_URL +  '/images/logo.png';
        }

        $('#detail-date-menu').html(date);
        $('#detail-content-menu').html(content);
        $('#detail-img-menu').attr('src', img);

        $('#detail-menu').modal('show');
    });

    $('.btn-import-file-menu').on('click', function (e) {
        e.preventDefault();
        $('#import-file-menu').click();
    });
    $(document).on('change', '#import-file-menu', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('form').submit();
    });
    $('#import_menu').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.load').removeClass('loading');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                $('#import-file-menu').val('');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.load').removeClass('loading');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                $('#import-file-menu').val('');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });

})(jQuery);