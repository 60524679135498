(function ($) {
    // CLick btn create check required staffId
    $(document).on('click', '.form-btn-create-leave', function (e) {
        e.preventDefault();
        var $this = $(this);
        var dev = $('#leave-add-staffId').val();
        if($('#form-create-leave').valid() == true) {
            if($('#leave-add-staffId').val().length == 0)
            {
                $('.error-leave-add-staffId').text('Bắt buộc chọn!');
            }
            else
            {
                $('.error-leave-add-staffId').text('');
            }

            if($('#leave-add-staffId').val().length > 0)
            {
                $('.form-btn-create-leave').attr('disabled', 'disabled');
                $this.closest('form').submit();
            }
        }
    });
    // ADD: Click loại ngỉ phép
    $(document).on('change', '#leave_type', function (e) {
        e.preventDefault();
        $('#date_from_leave').val('');
        $('#date_to_leave').val('');
        $('.box-check-radio').css('display', 'none');
        $('#date_to_leave').css('display', 'block');

        var date = $('select[name="leave_type"]').val();
        if (date == 1 || date == 2 || date == 6)
        {
            $('.box-check-radio').css('display', 'block');
        }
        if (date == 1 || date == 2 || date == 3 || date == 6)
        {
            $('#date_to_leave').css('display', 'none');
            $('#date_to_leave-error').css('display', 'none');
        }
    });

    // EDIT: Click loại ngỉ phép
    $(document).on('change', '#edit_leave_type', function (e) {
        e.preventDefault();
        $('.box-check-radio-edit').css('display', 'none');
        $('#date_to_leave_edit').css('display', 'block');

        let date = $('select[name="leave_type_edit"]').val();
        if (date == 1 || date == 2 || date == 6)
        {
            $('.box-check-radio-edit').css('display', 'block');
        }
        if (date == 1 || date == 2 || date == 3 || date == 6)
        {
            $('#date_to_leave_edit').css('display', 'none');
        }
    });

    // Create leave
    $('#form-create-leave').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('.form-btn-create-leave').removeAttr('disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('.form-btn-create-leave').removeAttr('disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/leave');
                });
            }
        }
    });

    // Edit leave law
    $('#form-edit-leave-law').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/leave');
                });
            }
        }
    });

    // Approve leave
    $('#form-approve-leave').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('.btn-form-approve-leave').removeAttr('disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('.btn-form-approve-leave').removeAttr('disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    $('#btn-form-approve-leave').click(function (e){
        e.preventDefault();
        if($('#form-approve-leave').valid() == true) {
            $('.btn-form-approve-leave').attr('disabled', 'disabled');
            $('#form-approve-leave').submit();
        }
    });

    // click button approve leave
    $(document).on('click', '.btn-approve-leave', function (e) {
        e.preventDefault();
        $('.box-check-radio-approve').css('display', 'none');
        $('#date_to_leave_approve').css('display', 'block');

        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var sc = $(this).attr('data-sc');
        var avatar = $(this).attr('data-avatar');
        var status = $(this).attr('data-status');
        var type = $(this).attr('data-type');
        var dateFrom = $(this).attr('data-dateFrom');
        var dateTo = $(this).attr('data-dateTo');
        var reason = $(this).attr('data-reason');
        var approverComment = $(this).attr('data-approverComment');
        var createdDate = $(this).attr('data-createdDate');

        if (type == 1 || type == 2 || type == 6)
        {
            $('.box-check-radio-approve').css('display', 'block');
            if (sc == 0){
                $('#s-leave').attr('checked', 'checked');
            } else{
                $('#c-leave').attr('checked', 'checked');
            }
        }
        if (type == 1 || type == 2 || type == 3 || type == 6){
            $('#date_to_leave_approve').css('display', 'none');
        }
        if (dateTo.length > 0){
            $('input[name="date_to_approve"]').val(dateTo);
        }
        if (sc == 1){
            $('#approved-l').attr('checked', 'checked');
            // $('#leave_reason_noApprove').attr('disabled', 'disabled');
        }else{
            $('#no-approve-l').attr('checked', 'checked');
        }
        if(status == 0){
            var sta = 'Không duyệt';
        }else if(status == 1){
            var sta = 'Đã duyệt';
        }else{
            var sta = 'Chờ duyệt';
        }
        $('input[name="id_user_approve"]').val(id);
        $('#dettail-approve-name').html(name);
        $('#approve-status').html(sta);
        $('#approve-date').html(createdDate);
        $('select[name="leave_type_approve"]').val(type);
        $('input[name="date_from_approve"]').val(dateFrom);
        $('textarea[name="leave_reason_a"]').val(reason);
        $('textarea[name="leave_reason_approve"]').val(approverComment);
        if (avatar.length > 0){
            $('#approve-img-leave').attr('src', avatar);
        }

        $('#modal_leave_approve').modal('show');
    });

    // click button edit leave
    $(document).on('click', '.btn-edit-leave', function (e) {
        e.preventDefault();
        $('#edit-img-leave').attr('src', BASE_URL +'/images/default-user.png');
        $('.box-check-radio-edit').css('display', 'none');
        $('#date_to_leave_edit').css('display', 'block');

        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var sc = $(this).attr('data-sc');
        var avatar = $(this).attr('data-avatar');
        var status = $(this).attr('data-status');
        var type = $(this).attr('data-type');
        var dateFrom = $(this).attr('data-dateFrom');
        var dateTo = $(this).attr('data-dateTo');
        var reason = $(this).attr('data-reason');
        var createdDate = $(this).attr('data-createdDate');

        if (type == 1 || type == 2 || type == 6)
        {
            $('.box-check-radio-edit').css('display', 'block');
            if (sc == 0){
                $('#s-leave_edit').attr('checked', 'checked');
            } else{
                $('#c-leave_edit').attr('checked', 'checked');
            }
        }
        if (type == 1 || type == 2 || type == 3 || type == 6){
            $('#date_to_leave_edit').css('display', 'none');
        }
        if (dateTo.length > 0){
            $('input[name="date_to_edit"]').val(dateTo);
        }

        if(status == 0){
            var sta = 'Không duyệt';
        }else if(status == 1){
            var sta = 'Đã duyệt';
        }else{
            var sta = 'Chờ duyệt';
        }
        $('input[name="id_user_edit"]').val(id);
        $('#dettail-edit-name').html(name);
        $('#edit-status').html(sta);
        $('#edit-date').html(createdDate);
        $('select[name="leave_type_edit"]').val(type);
        $('input[name="date_from_edit"]').val(dateFrom);
        $('textarea[name="leave_reason_edit"]').val(reason);
        if (avatar.length > 0){
            $('#edit-img-leave').attr('src', avatar);
        }

        $('#modal_leave_edit').modal('show');
    });

    // Edit leave
    $('#form-edit-leave').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/leave');
                });
            }
        }
    });

    // onChange search user leave
    $(document).on('change', '#leave-staffId', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });

    // onChange search month leave
    $(document).on('change', '#search_leave_month', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange search status leave
    $(document).on('change', '#search_leave-status', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange search type
    $(document).on('change', '.leave-page input[name="type"]', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });

    // Delete leave
    $(document).on('click', '.btn-delete-leave', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Leave',
            text: 'Bạn muốn xóa đơn nghỉ phép này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/leave/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

    // Click detail leave
    $(document).on('dblclick', '.detail-leave', function (e) {
        e.preventDefault();

        let name = $(this).attr('data-name');
        let avatar = $(this).attr('data-avatar');
        let status = $(this).attr('data-status');
        let type = $(this).attr('data-type');
        let sc = $(this).attr('data-sc');
        let dateFrom = $(this).attr('data-dateFrom');
        let dateTo = $(this).attr('data-dateTo');
        let reason = $(this).attr('data-reason');
        let approverName = $(this).attr('data-approverName');
        let approverComment = $(this).attr('data-approverComment');
        let createdDate = $(this).attr('data-createdDate');

        if (sc.length > 0 && sc == 0){
            var session = 'Sáng';
        }else if (sc.length > 0 && sc == 1) {
            var session = 'Chiều';
        }
        if (dateTo.length > 0){
            var date = dateFrom + ' - ' + dateTo;
        } else{
            var date = dateFrom;
        }
        if(status == 0){
            var sta = 'Không duyệt';
        }else if(status == 1){
            var sta = 'Đã duyệt';
        }else{
            var sta = 'Chờ duyệt';
        }
        // Loai nghỉ phép
        if(type == 1){
            var t = 'Đi muộn';
        } else if(type == 2){
            var t = 'Nửa ngày';
        } else if(type == 3){
            var t = '1 ngày';
        } else if(type == 4){
            var t = '2-5 ngày';
        } else if(type == 5){
            var t = '>5 ngày';
        } else if(type == 6){
            var t = 'Về sớm';
        } else {
            var t = '';
        }

        $('#detail-type').html(t);
        $('#detail-datetime').html(date);
        $('#detail-status-leave').html(sta);
        $('#detail-sc').html(session);
        $('#detail-name').html(name);
        $('#detail-reason').html(reason);
        $('#detail-approverName').html(approverName);
        $('#detail-approverComment').html(approverComment);
        $('#detail-date').html(createdDate);
        if (avatar.length > 0){
            $('#leave-img-detail').attr('src', avatar);
        }
        if (type != 0 && type != 1 && type != 2){
            $('.sesion-leave-detail').css('display', 'none');
        }else{

            $('.sesion-leave-detail').css('display', 'block');
        }

        $('#modal-leave-detail').modal('show');
    });

    // onChange click date to
    $(document).on('change', '#date_to_leave', function () {
        var date_from = new Date($('input[name="date_from"]').val());
        var date_to = new Date($('input[name="date_to"]').val());
        var to = $('input[name="date_to"]').val();

        if(date_to.getTime() > date_from.getTime())
        {
            var offset = date_to.getTime() - date_from.getTime();
            var totalDays = Math.round(offset / 1000 / 60 / 60 / 24) + 1;
            // 0: Chủ nhật, 6: thứ 7
            var total = 0;
            for (var i = 0; i < totalDays; i ++)
            {
                if(i == 0){
                    var new_int = date_from.getTime();
                }else{
                    var new_int = date_from.setDate(date_from.getDate() + 1);
                }
                 var new_date =  new Date(new_int);
                 var new_week = new_date.getDay();
                 if(new_week != 0 && new_week != 6)
                 {
                     total ++;
                 }
                console.log(new_week);
            }
            $('.show-date-leave').css('display', 'block');
            $('.total_date').html(total + ' ngày');
        }
        else if(to.length == 0)
        {
            $('.show-date-leave').css('display', 'none');
        }
    });
    // onChange click date from
    $(document).on('change', '#date_from_leave', function () {
        var date_from = new Date($('input[name="date_from"]').val());
        var dateFrom = new Date($('input[name="date_from"]').val());
        var datefrom = new Date($('input[name="date_from"]').val());
        var date__from = new Date($('input[name="form-btn-create-leave"]').val());
        var date_to = new Date($('input[name="date_to"]').val());
        var to = $('input[name="date_to"]').val();
        var type = $('select[name="leave_type"]').val();

        $('#date_to_leave').val('');

        if(date_to.getTime() > date_from.getTime())
        {
            var offset = date_to.getTime() - date_from.getTime();
            var totalDays = Math.round(offset / 1000 / 60 / 60 / 24) + 1;
            // 0(Chủ nhật), 6(thứ 7)
            var total = 0;
            for (var i = 0; i < totalDays; i ++)
            {
                if(i == 0){
                    var new_int = date_from.getTime();
                }else{
                    var new_int = date_from.setDate(date_from.getDate() + 1);
                }
                 var new_date =  new Date(new_int);
                 var new_week = new_date.getDay();
                 if(new_week != 0 && new_week != 6)
                 {
                     total ++;
                 }
            }
            $('.show-date-leave').css('display', 'block');
            $('.total_date').html(total + ' ngày');
            var date = $(this).val();
            // $('#date_to_leave').datetimepicker({minDate: date});
        }
        else if(to.length == 0)
        {
            $('.show-date-leave').css('display', 'none');
        }
        if (type == 4)
        {
            var check_int = dateFrom.setDate(dateFrom.getDate() + 1);
            var check_date =  new Date(check_int);
            var min_year = check_date.getFullYear();
            var min_month = check_date.getMonth();
            var min_day = check_date.getDate();

            var count = 0;
            var c = 0;
            for (var j = 0; j < 7; j ++)
            {
                if(j == 0){
                    var new_int = datefrom.getTime();
                }else{
                    var max_int = datefrom.setDate(datefrom.getDate() + 1);
                }
                var max_int = datefrom.setDate(datefrom.getDate() + 1);
                var max_date =  new Date(max_int);
                var max_week = max_date.getDay();
                if(max_week != 0 && max_week != 6)
                {
                    count ++;
                }
                c ++;
                if (count == 5){
                    break;
                }
            }

            // var m_int = date__from.setDate(date__from.getDate() + c - 1);
            // var m_date =  new Date(m_int);
            // var max_year = m_date.getFullYear();
            // var max_month = m_date.getMonth();
            // var max_day = m_date.getDate();

            // $('#date_to_leave').datetimepicker({
            //     maxDate: max_year + '/' + (max_month + 1) + '/' + max_day,
            //     minDate: min_year + '/' + (min_month + 1) + '/' + min_day
            // });
        }
        else if(type == 5)
        {
            var count = 0;
            var c = 0;
            for (var j = 0; j < 7; j ++)
            {
                if(j == 0){
                    var new_int = datefrom.getTime();
                }else{
                    var max_int = datefrom.setDate(datefrom.getDate() + 1);
                }
                var max_int = datefrom.setDate(datefrom.getDate() + 1);
                var max_date =  new Date(max_int);
                var max_week = max_date.getDay();
                if(max_week != 0 && max_week != 6)
                {
                    count ++;
                }
                c ++;
                if (count == 5){
                    break;
                }
            }

            // var m_int = date__from.setDate(date__from.getDate() + c);
            // var m_date =  new Date(m_int);
            // var min_year = m_date.getFullYear();
            // var min_month = m_date.getMonth();
            // var min_day = m_date.getDate();
            //
            // $('#date_to_leave').datetimepicker({
            //     minDate: min_year + '/' + (min_month + 1) + '/' + min_day,
            //     maxDate: (min_year + 5) + '/' + (min_month + 1) + '/' + min_day
            // });
        }
    });
    //// Chi tiết nghỉ phép
        // onChange search user leave detail
    // $(document).on('change', '#leave-detail-staffId', function () {
    //     var $this = $(this);
    //     $this.closest('form').submit();
    // });
    //     // onChange search user leave limit
    // $(document).on('change', '#limit_leave_detail', function () {
    //     var $this = $(this);
    //     $this.closest('form').submit();
    // });

})(jQuery);
