(function ($) {
    $("#draggable").draggable({
        scroll: true,
        cursor: "move",
        containment: "body"
    });

    $('#login-form').ajaxForm({
        beforeSend: function () {
            $('.login__form .user-mess').empty();
            $('.login__form .pass-mess').empty();
            $('.login__form .general-mess').empty();
            $('section.login').addClass('loging');
        },
        success: function (data) {
            console.log(data);
            if (data.error) {
                var err_user_mes = data.error['email'] ? data.error['email'] : '';
                var err_mes_pass = data.error['password'] ? data.error['password'] : '';
                var err_general_mes = data.error['general'] ? data.error['general'] : '';
                $('.login__form .user-mess').append(err_user_mes);
                $('.login__form .pass-mess').append(err_mes_pass);
                $('.login__form .general-mess').append(err_general_mes);
                setTimeout(function () {
                    $('section.login').removeClass('loging');
                }, 500);
            } else {
                $('section.login .overlay').html(data.success);
                setTimeout(function () {
                    $(location).attr('href', data.url)
                }, 500);
            }
        }
    });

//     $('.login__form .btn-login').click(function (e) {
//         e.preventDefault();
//
//         $('.login__form .user-mess').empty();
//         $('.login__form .pass-mess').empty();
//         $('.login__form .general-mess').empty();
//         $.ajaxSetup({
//             headers: {
//                 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
//             }
//         });
//         $.ajax(BASE_URL + '/login',{
//             type: 'POST',
//             dataType: 'json',
//             data: {
//                 email: $('#email').val(),
//                 password: $('#password').val(),
//                 redirect: $('input[name="redirect"]').val(),
//                 remember_acc: $('#remember-acc').is(':checked') ? 1 : 0,
//             },
//             beforeSend:function(){
//                 $('section.login').addClass('loging');
//             },
//             success:function (data){
//                 console.log(data);
//                 if (data.error){
//                     var err_user_mes = data.error['email'] ? data.error['email'] : '';
//                     var err_mes_pass = data.error['password'] ? data.error['password'] : '';
//                     var err_general_mes = data.error['general'] ? data.error['general'] : '';
//                     $('.login__form .user-mess').append(err_user_mes);
//                     $('.login__form .pass-mess').append(err_mes_pass);
//                     $('.login__form .general-mess').append(err_general_mes);
//                     setTimeout(function () {
//                         $('section.login').removeClass('loging');
//                     },500);
//                 }
//                 else {
//                     $('section.login .overlay').html(data.success);
//                     setTimeout(function () {
//                         $(location).attr('href', data.url)
//                     },500);
//                 }
//             }
//         });
//     });
    $(document).on('click', '.login-page .pass--show', function () {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $(".login-page #password");
        input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password');
    });

})(jQuery);
