(function ($) {
    // Create contract
    $('#form-create-salary-review').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/salary-review');
                });
            }
        }
    });
    $('.btn-import-file-review').on('click', function (e) {
        e.preventDefault();
        $('#upload-file-review').click();
    });
    // Show name file upload
    $(document).on('change', '#upload-file-review', function (e) {
        e.preventDefault();
        $('.btn-import-file-review').html(e.target.files[0].name);
    });

    // $(document).on('click', '#form-btn-create-salary-review', function(){
    //     $('#salary-review-add-leaderId').clone().appendTo('#create-salary-review .bootstrap-select');
    //     $('#create-salary-review .bootstrap-select .selectpicker').first().remove();
    // });

    // Edit salary-review
    $('#form-edit-salary-review').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // click button edit salary review
    $(document).on('click', '.btn-edit-salary-review', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var date = $(this).attr('data-date');
        var salary = $(this).attr('data-salary');
        var status = $(this).attr('data-status');
        var comment = $(this).attr('data-comment');
        var createTime = $(this).attr('data-createTime');
        var avatar = $(this).attr('data-avatar');
        var leaderId = $(this).attr('data-leaderid');
        if (status == 1){
            var s = 'Đã duyệt';
        }else{
            var s = 'Chưa duyệt';
        }
        $('input[name="id_review"]').val(id);
        $('textarea[name="edit_comment"]').html(comment);
        $('input[name="edit_date"]').val(date);
        $('input[name="edit_salary"]').val(salary);
        $('#salary-review-edit-leaderId').selectpicker('val', leaderId);
        $('#edit-salary-review-status').html(s);
        $('#edit-salary-review-name').html(name);
        $('#edit-salary-review-createDate').html(createTime);
        if (avatar.length > 0){
            $('#edit-salary-review-img').attr('src', avatar);
        }

        $('#edit-salary-review').modal('show');
    });

    $('.btn-import-file-edit-review').on('click', function (e) {
        e.preventDefault();
        $('#upload-file-edit-review').click();
    });
    // Show name file upload
    $(document).on('change', '#upload-file-review', function (e) {
        e.preventDefault();
        $('.btn-import-file-edit-review').html(e.target.files[0].name);
    });

    // Approve salary-review
    $('#form-approve-salary-review').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // click button approve salary review
    $(document).on('click', '.btn-approve-salary-review', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var leader = $(this).attr('data-leader');
        var date = $(this).attr('data-date');
        var salary = $(this).attr('data-salary');
        var status = $(this).attr('data-status');
        var comment = $(this).attr('data-comment');
        var createTime = $(this).attr('data-createTime');
        var avatar = $(this).attr('data-avatar');
        if (status == 1){
            var s = 'Đã duyệt';
        }else{
            var s = 'Chưa duyệt';
        }

        $('input[name="id_review_approve"]').val(id);
        $('#approve-salary-review-name').html(name);
        $('#approve-salary-review-date').html(date);
        $('#approve-salary-review-status').html(s);
        $('#approve-salary-review-leader').html(leader);
        $('#approve-salary-review-comment').html(comment);
        $('#approve-salary-review-salary').html(salary);
        $('#approve-salary-review-createDate').html(createTime);
        if (avatar.length > 0){
            $('#approve-salary-review-img').attr('src', avatar);
        }

        $('#modal-salary-review-approve').modal('show');
    });
    // onChange search staffId salary-review
    $(document).on('change', '#salary-review-search-staffId', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });

    // Delete salary review
    $(document).on('click', '.btn-delete-salary-review', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Review Salary',
            text: 'Bạn muốn xóa review lương?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/salary-review/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click detail salary review
    $(document).on('dblclick', '.salary-review-detail', function (e) {
        e.preventDefault();

        var name = $(this).attr('data-name');
        var leader = $(this).attr('data-leader');
        var date = $(this).attr('data-date');
        var salary = $(this).attr('data-salary');
        var status = $(this).attr('data-status');
        var comment = $(this).attr('data-comment');
        var createTime = $(this).attr('data-createTime');
        var avatar = $(this).attr('data-avatar');
        var newSalary = $(this).attr('data-newSalary');
        var result = $(this).attr('data-result');
        if (status == 1){
            var s = 'Đã nhận xét';
        }else{
            var s = 'Chưa nhận xét';
        }

        $('#detail-salary-review-name').html(name);
        $('#detail-salary-review-date').html(date);
        $('#detail-salary-review-status').html(s);
        $('#detail-salary-review-leader').html(leader);
        $('#detail-salary-review-comment').html(comment);
        $('#detail-salary-review-salary').html(salary);
        $('#detail-salary-review-createDate').html(createTime);
        $('#detail-salary-review-newSalary').html(newSalary);
        $('#detail-salary-review-result').html(result);
        if (avatar.length > 0){
            $('#detail-salary-review-img').attr('src', avatar);
        }

        $('#modal-salary-review-detail').modal('show');
    });

    // Format salary
    $(document).ready(function(){
        $('#salary-review-add-salary').keyup(function(event){
            // skip for arrow keys
            if(event.which >= 37 && event.which <= 40){
                event.preventDefault();
            }
            var $this = $(this);
            var num = $this.val().replace(/,/gi, "").split("").reverse().join("");
            var num2 = RemoveRougeChar(num.replace(/(.{3})/g,"$1,").split("").reverse().join(""));

            // console.log(num2);
            $this.val(num2);
        });
        $('#salary-review-edit-salary').keyup(function(event){
            // skip for arrow keys
            if(event.which >= 37 && event.which <= 40){
                event.preventDefault();
            }
            var $this = $(this);
            var num = $this.val().replace(/,/gi, "").split("").reverse().join("");
            var num2 = RemoveRougeChar(num.replace(/(.{3})/g,"$1,").split("").reverse().join(""));

            // console.log(num2);
            $this.val(num2);
        });
        $('#salary-review-approve-salary').keyup(function(event){
            // skip for arrow keys
            if(event.which >= 37 && event.which <= 40){
                event.preventDefault();
            }
            var $this = $(this);
            var num = $this.val().replace(/,/gi, "").split("").reverse().join("");
            var num2 = RemoveRougeChar(num.replace(/(.{3})/g,"$1,").split("").reverse().join(""));

            // console.log(num2);
            $this.val(num2);
        });
    });
    function RemoveRougeChar(convertString){
        if(convertString.substring(0,1) == ","){
            return convertString.substring(1, convertString.length)
        }
        return convertString;
    }

})(jQuery);