(function ($) {
    $(document).on('click', '#form-btn-create-noti', function (e) {
        e.preventDefault();
        var $this = $(this);
        var dev = $('#noti-add-staffId').val();
        var type = $('#noti-add-type').val();
        if($('#form-create-noti').valid() == true) {
            if($('#noti-add-staffId').val().length == 0 && type != 1)
            {
                $('.error-noti-staffId').text('Bắt buộc nhập!');
            }
            else
            {
                $('.error-noti-staffId').text('');
            }

            if($('#noti-add-staffId').val().length > 0 && type != 1 || type == 1)
            {
                $this.closest('form').submit();
            }
        }
    });
    // Create Notification
    $('#form-create-noti').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/notification');
                });
            }
        }
    });

    // Delete Notification
    $(document).on('click', '.btn-delete-noti', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Notification',
            text: 'Bạn muốn xóa thông báo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/notification/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click detail contract (dblclick'dblclick')
    $(document).on('click', '.noti-detail', function (e) {
        e.preventDefault();

        var title = $(this).attr('data-title');
        var time = $(this).attr('data-time');
        var message = $(this).attr('data-message');
        //
        $('#detail-noti-title').html(title);
        $('#detail-noti-time').html(time);
        $('#detail-noti-message').html(message);

        $('#modal-noti-detail').modal('show');
    });
    // onChange type
    $(document).on('change', '#noti-add-type', function (e) {
        e.preventDefault();
        $('#noti-add-staffId').removeAttr('disabled', 'disabled')
        var item = $('#noti-add-type').val();

        if(item == 1){
            $('#noti-add-staffId').attr('disabled', 'disabled');
        }
    });

})(jQuery);