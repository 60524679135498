(function ($) {
    let home_slider =  fs.home_slider('.home-slide');
    let video_carousel = [];
    let carousel = [];
    if($('.video__channel').length){
        $('.video__channel').each(function(index, element){
            let id = $(this).attr('data-id');
            let strClass = 'id-'+id;
            let addClass = '.'+strClass;
            video_carousel[index] = fs.video_carousel('.parent-'+id,'.video__channel '+addClass);
        });
    }
    if ($('.see-same').length){
        var see_same = fs.video_carousel('.see-same','.see-same .video-carousel');
    }
    if ($('.channel-carousel').length){
        var channel_carousel = fs.channel_carousel('.channel-carousel');
    }
    if ($('.playlist').length){
        var playlist = fs.video_carousel('.playlist','.playlist .playlist-carousel');
    }
    var videoCarousel = fs.video_carousel('.page-manager-content ','.video-carousel');

})(jQuery);
