(function ($) {
    // Create event
    $('#form-create-event').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            var href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.error_input){
                $('.footer .overlay').removeClass('overlaing');
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Click input upload image create
    $('#btn-upload-img-create-event').click(function (e) {
        e.preventDefault();
        $('#img_event').click();
    });
    // Show Image create
    $('#img_event').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('show-img-event');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-create-event').html(e.target.files[0].name);
        $('#show-img-event').css('display', 'block');
    });

    // Edit event
    $('#form-edit-event').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            var href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.error_input){
                $('.footer .overlay').removeClass('overlaing');
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    $(document).on('click', '.btn-edit-event', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var date = $(this).attr('data-date');
        var address = $(this).attr('data-address');
        var content = $(this).attr('data-content');
        var img = $(this).attr('data-img');

        $('input[name="edit_id"]').val(id);
        $('input[name="edit_name"]').val(name);
        $('input[name="edit_date"]').val(date);
        $('input[name="edit_address"]').val(address);
        $('textarea[name="edit_content"]').html(content);
        $('#edit-event .note-editable').html(content);
        $('#show-img-edit-event').attr('src', img);

        $('#edit-event').modal('show');
    });
    // Double click detail event
    $(document).on('dblclick', '.double-detail-event', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');

        window.open(BASE_URL + '/event/detail?id=' + id, '_blank');
    });

    // Click input upload image edit
    $('#btn-upload-img-edit-event').click(function (e) {
        e.preventDefault();
        $('#edit_img_event').click();
    });
    // Show Image edit
    $('#edit_img_event').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('show-img-edit-event');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-event').html(e.target.files[0].name);
        $('#show-img-edit-event').css('display', 'block');
    });
    // Click btn xóa tham gia event
    $(document).on('click', '.btn-event-delete', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Event',
            text: 'Bạn muốn xóa sự kiện này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/event/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

    // Click btn tham gia evet
    $(document).on('click', '.btn-event-join', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var eventID = $(this).attr('data-event');
        var href = window.location.href;

        Swal.fire({
            title: 'Join Event',
            text: 'Bạn muốn tham gia sự kiện này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/event/join',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        eventID: eventID,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click btn xóa tham gia evet
    $(document).on('click', '.btn-event-delete-join', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Join Event',
            text: 'Bạn muốn xóa thành viên tham gia sự kiện này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/event/delete-join',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click btn checkin evet
    $(document).on('click', '.btn-event-checkin', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var eventID = $(this).attr('data-event');
        var href = window.location.href;

        Swal.fire({
            title: 'Checkin Event',
            text: 'Bạn muốn checkin sự kiện này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/event/checkin',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        eventID: eventID,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);