(function ($) {
    // Create checkin
    $('#form-create-checkin').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('#form-btn-create-checkin').removeAttr('disabled', 'disabled');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('#form-btn-create-checkin').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/check-in');
                });
            }
        }
    });
    $('#form-btn-create-checkin').click(function (e){
        e.preventDefault();
        if($('#form-create-checkin').valid() == true) {
            $('#form-btn-create-checkin').attr('disabled', 'disabled');
            $('#form-create-checkin').submit();
        }
    });
    // Create Checkin Hộ
    $('#form-create-ho-checkin').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('#form-btn-create-checkin-ho').removeAttr('disabled', 'disabled');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('#form-btn-create-checkin-ho').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/check-in');
                });
            }
        }
    });
    $('#form-btn-create-checkin-ho').click(function (e){
        e.preventDefault();
        if($('#form-create-ho-checkin').valid() == true) {
            $('#form-btn-create-checkin-ho').attr('disabled', 'disabled');
            $('#form-create-ho-checkin').submit();
        }
    });
    // Click edit checkin
    $(document).on('click', '.btn-edit-checkin', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var avatar = $(this).attr('data-avatar');
        var date = $(this).attr('data-date');
        var order_rice = $(this).attr('data-order');
        var number_rice = $(this).attr('data-quantity');
        if(number_rice == 0){
            number_rice = 1;
        }

        if(order_rice == 1){
            $('input[name="edit_order_rice"]').attr('checked', 'checked');
        }

        $('input[name="id_checkin"]').val(id);
        $('#edit-name-checkin').html(name);
        $('#edit-date-checkin').html(date);
        $('input[name="edit_number_rice"]').val(number_rice);
        if(avatar.length > 0){
            $('#edit-img-checkin').attr('src', avatar);
        }

        $('#modal-edit-checkin').modal('show');
    });
    // Edit Checkin
    $('#form-edit-checkin').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            var href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Update rice
    $('#form-update-rice-checkin').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            var href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // onChange search user
    $(document).on('change', '#checkin-staffId', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange search date
    $(document).on('change', '#search_date_checkin', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange search list checkin
    $(document).on('change', '#search_list_checkin', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange search month history checkin
    $(document).on('change', '#search_date_history_checkin', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange limit checkin
    $(document).on('change', '#limit_checkin', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // Click detail checkin
    $(document).on('dblclick', '.detail-checkin', function (e) {
        e.preventDefault();

        var name = $(this).attr('data-name');
        var avatar = $(this).attr('data-avatar');
        var checkin = $(this).attr('data-checkin');
        var statusLunch = $(this).attr('data-order');
        var quantity = $(this).attr('data-quantity');
        var date = $(this).attr('data-date');
        var time = $(this).attr('data-time');
        var money = $(this).attr('data-money');
        var reason = $(this).attr('data-reason');
        var checkin_ho = $(this).attr('data-checkin_ho');
        if (checkin == 0){
            var check = 'Chưa điểm danh';
        }else{
            var check = 'Đã điểm danh';
        }
        if(statusLunch == 1 && quantity > 0){
            var lunch = 'Có';
        }else{
            var lunch = 'Không';
        }
        if (quantity > 0){
            var num = quantity;
        }else{
            var num = 0;
        }

        $('#detail-name-checkin').html(name);
        $('#detail-status-checkin').html(check);
        $('#detail-order-rice').html(lunch);
        $('#detail-num-rice').html(num);
        $('#detail-money').html(money);
        $('#detail-reason').html(reason);
        $('#detail-time').html(time);
        $('#detail-date').html(date);
        $('#detail-checkin-ho').html(checkin_ho);
        if (avatar.length > 0){
            $('#checkin-img-detail').attr('src', avatar);
        }

        $('#check-in-detail').modal('show');
    });


    // onChange search date order rice detail
    $(document).on('change', '#search_date_order_rice_detail', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });


    $(function () {
        $('.popover-checkin').popover({
            trigger: 'hover'
        });
        // $('.popover-checkin').popover();
    });
})(jQuery);