(function ($) {

    (function () {
        $('.btn-forgot-email').click(function (e) {
            e.preventDefault();

            $('.login__form .user-mess').empty();
            $('.login__form .pass-mess').empty();
            $('.login__form .general-mess').empty();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/xac-nhan-email',{
                type: 'POST',
                dataType: 'json',
                data: {
                    email: $('#forgot_email').val(),
                },
                beforeSend:function(){
                    $('section.login').addClass('loging');
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        var err_user_mes = data.error['email'] ? data.error['email'] : '';
                        var err_general_mes = data.error['general'] ? data.error['general'] : '';
                        $('.login__form .user-mess').append(err_user_mes);
                        $('.login__form .general-mess').append(err_general_mes);
                        setTimeout(function () {
                            $('section.login').removeClass('loging');
                        },500);
                    }
                    else {
                        $('section.login .overlay').html(data.success);
                        setTimeout(function () {
                            $(location).attr('href', data.url)
                        },500);
                    }
                }
            });
        })
    })();
})(jQuery);
