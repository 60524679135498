module.exports = {

    home_slider: function (select) {
        return new Swiper(select, {
            'navigation': {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            'loop': true,
            'draggable': false,
            'autoplay': {
                'delay': 5000,
            },
            'fadeEffect': {
                'crossFade': true
            },
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
        });

    },
    video_carousel: function (parent,select) {
        return new Swiper(select, {

            slidesPerView: 6,
            spaceBetween: 10,
            pagination: false,
            preloadImages: false,
            // Enable lazy loading

            navigation: {
                nextEl: parent + ' .see-same-next',
                prevEl: parent + ' .see-same-prev',
            },
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            breakpoints: {
                1600: {
                    slidesPerView: 5,
                },
                1440: {
                    slidesPerView: 4,
                },
                1150: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },

                320: {
                    slidesPerView: 1,
                }
            }
        });
    },
    swiper_reset: function(name){
        name.destroy(true,true);
    },
    channel_carousel: function (select) {
        return new Swiper(select,{
            slidesPerView: 6,
            spaceBetween: 10,
            pagination: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            preloadImages: false,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            longSwipes: false,
            breakpoints: {
                1600: {
                    slidesPerView: 5,
                },
                1440: {
                    slidesPerView: 4,
                },
                1150: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },

                320: {
                    slidesPerView: 1,
                }
            }
        })
    },
    channelRelate: function (select) {
        return new Swiper(select,{
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            mousewheel: true,
        })
    },
    lazyload:function () {
        $('.lazy').each(function () {
            var src = $(this).attr('data-src');
            $(this).attr('src',src);

        })
    }
};
