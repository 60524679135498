(function ($) {

    (function () {
        $('.btn-forgot-code').click(function (e) {
            e.preventDefault();

            $('.login__form .user-mess').empty();
            $('.login__form .pass-mess').empty();
            $('.login__form .general-mess').empty();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/nhap-ma',{
                type: 'POST',
                dataType: 'json',
                data: {
                    email: $('#forgot_email_code').val(),
                    code: $('#forgot_code').val(),
                },
                beforeSend:function(){
                    $('section.login').addClass('loging');
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        var err_user_mes = data.error['code'] ? data.error['code'] : '';
                        var err_general_mes = data.error['general'] ? data.error['general'] : '';
                        $('.login__form .user-mess').append(err_user_mes);
                        $('.login__form .general-mess').append(err_general_mes);
                        setTimeout(function () {
                            $('section.login').removeClass('loging');
                        },500);
                    }
                    else {
                        $('section.login .overlay').html(data.success);
                        setTimeout(function () {
                            // $(location).attr('href', data.url)
                            $.ajaxSetup({
                                headers: {
                                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                                }
                            });
                            $.ajax(BASE_URL + '/login',{
                                type: 'POST',
                                dataType: 'json',
                                data: {
                                    email: data.email,
                                    password: data.code,
                                    redirect: '',
                                    remember_acc: 0,
                                },
                                beforeSend:function(){
                                    $('section.login').addClass('loging');
                                },
                                success:function (data){
                                    console.log(data);
                                    if (data.error){
                                        $('section.login').removeClass('loging');
                                        $(location).attr('href', BASE_URL);
                                    }
                                    else {
                                        $('section.login').removeClass('loging');
                                        $(location).attr('href', BASE_URL + '/check-in');
                                    }
                                }
                            });
                        },500);
                    }
                }
            });
        })
    })();

    $(document).on('click', '.change-pass-page .show-old-pass', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $(".change-pass-page #old_password");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password');
    });
    $(document).on('click', '.change-pass-page .show-new-pass', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $(".change-pass-page #new_password");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password');
    });
    $(document).on('click', '.change-pass-page .show-again-pass', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $(".change-pass-page #password_again");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password');
    });

    (function () {
        $('.login__form .btn-change-pass').click(function (e) {
            e.preventDefault();

            $('.login__form .pass-agian-mess').empty();
            $('.login__form .pass-old-mess').empty();
            $('.login__form .pass-mess').empty();
            $('.login__form .general-mess').empty();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/change-password',{
                type: 'POST',
                dataType: 'json',
                data: {
                    old_pass: $('#old_password').val(),
                    new_pass: $('#new_password').val(),
                    again_pass: $('#password_again').val(),
                },
                beforeSend:function(){
                    $('section.login').addClass('loging');
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        var err_pass_mes = data.error['new_pass'] ? data.error['new_pass'] : '';
                        var err_pass_old_mes = data.error['old_pass'] ? data.error['old_pass'] : '';
                        var err_pass_again_mes = data.error['again_pass'] ? data.error['again_pass'] : '';
                        var err_general_mes = data.error['general'] ? data.error['general'] : '';
                        $('.login__form .pass-mess').append(err_pass_mes);
                        $('.login__form .pass-old-mess').append(err_pass_old_mes);
                        $('.login__form .pass-agian-mess').append(err_pass_again_mes);
                        $('.login__form .general-mess').append(err_general_mes);
                        setTimeout(function () {
                            $('section.login').removeClass('loging');
                        },500);
                    }
                    else {
                        $('section.login .overlay').html(data.success);
                        setTimeout(function () {
                            $(location).attr('href', data.url)
                        },500);
                    }
                }
            });
        })
    })();
})(jQuery);
