(function ($) {
    $('.lazy').each(function () {
        let src = $(this).attr('data-src');
        $(this).attr('src',src);
    });
    // Tooltip
    (function () {
        // Tooltip page video detail
        $('.video__meta [data-toggle="tooltip"]').tooltip();
        // Tooltip page channle detail
        $('.channel__follow [data-toggle="tooltip"]').tooltip();
    })();
    (function () {
        $('.comment__content .content-text').each(function( index ) {
            let el = $(this);
            if (el.prop('scrollHeight') > Math.round(el.height())){
                el.parent().removeClass('comment--less');
            }
        });
        $('.comment__viewmore label').click(function () {
            $this = $(this);
            $this.parents('.comment__viewmore').parent('.comment__content').toggleClass('comment--long');
        })
    })();
    (function () {
        if ( $( window ).width()<992){
            $('body').removeClass('menu-opened');
        } else {
            if(!$('body').hasClass('video__detail')){
                $('body').addClass('menu-opened');
            }
        }
        $( window ).resize(function() {
            if ( $( window ).width()<992){
                $('body').removeClass('menu-opened');
            } else {
                if(!$('body').hasClass('video__detail')){
                    $('body').addClass('menu-opened');
                }
            }
        });
    })();
    // Laydyload
    (function () {
        $('.lazy').each(function () {
            var src = $(this).attr('data-src');
            $(this).attr('src',src);

        })
    })();
    (function () {

    })();
    //hover box video
    (function () {
        $('.box-video .thumnail a').hover(function() {

            var img = $(this).find('img');
            var preview = img.attr('data-preview');
            img.attr('src',preview);

        }, function() {
            var img = $(this).find('img');
            var im = img.attr('data-src');
            img.attr('src',im);
        })
    })();

    // Repeater
    $('.repeater').repeater({
        show: function () {
            $(this).find('.namefile').empty();
            $(this).find('input[type=hidden]').val('');
            $(this).show();
        },
    });
    (function () {
        $(document).on('change','.file-sub',function (e) {
            if (e.target.files[0]){
                var fileName = e.target.files[0].name;
                console.log(fileName);
                $(this).next('span.namefile').html(fileName);
            }
            else {
                $(this).next('span.namefile').html('');
            }
        });

    })();
})(jQuery);


// debounce
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
