var jwDefaults = {
    "abouttext": "MeClip",
    "aboutlink": BASE_URL,
    "aspectratio": "16:9",
    "pid": "glc5eiuf",
    "key": "t0dGkIWYvs7xQNSunfAIyTS5A+8TnCIJsTV2bjloq9M=",
    "autostart":true,
    "mute": false,
    "playbackRateControls": false,
    "controls": true,
    "displaydescription": false,
    "flashplayer": "//ssl.p.jwpcdn.com/player/v/8.9.3/jwplayer.flash.swf",
    "floating" :{
        "dismissible": true
    },
    "width": "100%",
    "skin" : {
        "name": "yc-jwplayer"
    },
    "preload": "none",
};
jwplayer.defaults = jwDefaults;
