(function ($) {
    $.datetimepicker.setLocale('vi');
    $('#search_date_checkin').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('#add_menu_date').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('#search_date_menu').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('#search_date_history_checkin').datetimepicker({
        timepicker:false,
        format:'Y/m',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('#search_date_leave').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('#search_leave_month').datetimepicker({
        timepicker:false,
        format:'Y/m',
        viewMode: 'years',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
    });
    $('#late-search-month').datetimepicker({
        timepicker:false,
        format:'Y/m',
        viewMode: 'years',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
    });
    $('.profile_form_date').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('.datetimepicker').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        beforeShowDay: $.datepicker.noWeekends,
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
    });
    $('.datetimepicker-weekend').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
    });
    $('.event-datetimepicker').datetimepicker({
        // timepicker:false,
        format:'Y/m/d H:i',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
    });
    $('.datetimepicker-now').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        beforeShowDay: $.datepicker.noWeekends,
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
        minDate:'-1d'
    });
    $('.datetimepicker-max-now').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        beforeShowDay: $.datepicker.noWeekends,
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
        maxDate:'+1d'
    });
    $('.datetimepicker-month').datetimepicker({
        timepicker:false,
        format:'Y/m',
        beforeShowDay: $.datepicker.noWeekends,
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
    });
})(jQuery);
