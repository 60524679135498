(function ($) {
    // onChange search user system
    $(document).on('change', '#system-management-staffId', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange search date system
    $(document).on('change', '#search-system-management-date', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange search type
    $(document).on('change', '.search_system-management-type', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });

    // Delete system
    $(document).on('click', '.btn-delete-system', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var type = $(this).attr('data-type');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete',
            text: 'Bạn chắc chắn muốn xóa?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/system-management/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        type: type,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(this).parent().parent().remove();
                                // $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);