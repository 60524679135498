(function ($) {
    $('.profile_avatar').dropify({
        tpl: {
            wrap:            '<div class="dropify-wrapper"></div>',
            loader:          '',
            message:         '',
            preview:         '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"><div class="dropify-infos-inner"><i class="fas fa-camera"></i></div></div></div>',
            filename:        '',
            clearButton:     '<button type="button" class="dropify-clear">{{ remove }}</button>',
            errorLine:       '<p class="dropify-error">{{ error }}</p>',
            errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>'
        }
    });




    var channel_cover = $('.channel_cover').dropify({
        messages: {
            'default': 'Kéo thả hoặc chọn từ thiết bị của bạn',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        }
    });

    $('.channel .modal-channel-cover .btn-cancel').on('click',function (e) {
        e.preventDefault();
        $('.channel .modal-channel-cover input[name=channel_cover]').val('');
        $('.modal-channel-cover .dropify-clear').trigger('click');
    });
    $('.channel .modal-channel-cover .btn-select').on('click',function (e) {
        e.preventDefault();
        var img = '';
        if ($('.channel .modal-channel-cover .dropify-preview').find('img').length){
            img = ($('.channel .modal-channel-cover .dropify-preview').find('img').attr('src'));
        }
        if (img){
            $('.channel__banner .channel--bg').css('background-image', 'url(' + img + ')');
        }
    });

    $('.video-thumb-upload').dropify({
        messages: {
            'default': 'Hình ảnh thu nhỏ tùy chỉnh',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });

    $('.playlist__thumb').dropify({
        messages: {
            'default': 'Hình ảnh thu nhỏ playlist',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });
    $('.live__thumb').dropify({
        messages: {
            'default': 'Hình ảnh cho Livestream',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove': 'Hủy',
            'error': 'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });
    $('.info-personal .avatar').dropify({
        messages: {
            'default': 'Hình ảnh avatar',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove': 'Hủy',
            'error': 'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });
})(jQuery);
