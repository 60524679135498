(function ($) {
    // CLick btn create check required staffId
    $(document).on('click', '#form-btn-create-project', function (e) {
        e.preventDefault();
        var $this = $(this);
        var dev = $('#project-create-staffId_dev').val();
        var test = $('#project-create-staffId_tester').val();
        if($('#form-create-project').valid() == true) {
            if($('#project-create-staffId_dev').val().length == 0)
            {
                $('.error-staffId-dev').text('Bắt buộc nhập!');
            }
            else
            {
                $('.error-staffId-dev').text('');
            }
            if($('#project-create-staffId_tester').val().length == 0)
            {
                $('.error-staffId-tester').text('Bắt buộc nhập!');
            }
            else
            {
                $('.error-staffId-tester').text('');
            }

            if($('#project-create-staffId_dev').val().length > 0 && $('#project-create-staffId_tester').val().length > 0)
            {
                $this.closest('form').submit();
            }
        }
    });
    // Create contract
    $('#form-create-project').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/project');
                });
            }
        }
    });
    // Create: onChange chọn thành viên dev
    $(document).on('change', '#project-create-staffId_dev', function (e) {
        e.preventDefault();
        if ($('#project-create-staffId_dev').val().length > 0){
            $('.error-staffId-dev').text('');
        }
        $('#create-project .project-members .members-dev ul').html('');
        $('#create-project .project_select__members-dev .bootstrap-select li.selected').each(function( index ) {
            // let id = $(this).attr('data-id');
            // if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
                $(this).clone().appendTo('#create-project .project-members .members-dev ul');
            // }
        });
    });
    // Create: onChange chọn thành viên tester
    $(document).on('change', '#project-create-staffId_tester', function (e) {
        e.preventDefault();
        if ($('#project-create-staffId_tester').val().length > 0){
            $('.error-staffId-tester').text('');
        }
        $('#create-project .project-members .members-test ul').html('');
        $('#create-project .project_select__members-tester .bootstrap-select li.selected').each(function( index ) {
            // let id = $(this).attr('data-id');
            // if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
                $(this).clone().appendTo('#create-project .project-members .members-test ul');
            // }
        });
    });

    // CLick btn edit check required staffId
    $(document).on('click', '#form-btn-edit-project', function (e) {
        e.preventDefault();
        var $this = $(this);
        var dev = $('#project-edit-staffId_dev').val();
        var test = $('#project-edit-staffId_tester').val();
        if($('#form-edit-project').valid() == true) {
            if($('#project-edit-staffId_dev').val().length == 0)
            {
                $('.error-edit-staffId-dev').text('Bắt buộc nhập!');
            }
            else
            {
                $('.error-edit-staffId-dev').text('');
            }
            if($('#project-edit-staffId_tester').val().length == 0)
            {
                $('.error-edit-staffId-tester').text('Bắt buộc nhập!');
            }
            else
            {
                $('.error-edit-staffId-tester').text('');
            }

            if($('#project-edit-staffId_dev').val().length > 0 && $('#project-edit-staffId_tester').val().length > 0)
            {
                $this.closest('form').submit();
            }
        }
    });
    // Edit: project
    $('#form-edit-project').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });

    // Edit onChange chọn thành viên dev
    $(document).on('change', '#project-edit-staffId_dev', function (e) {
        e.preventDefault();
        $('#edit-project .project-members .members-dev ul').html('');
        $('#edit-project .project_select__members-dev .bootstrap-select li.selected').each(function( index ) {
            $(this).clone().appendTo('#edit-project .project-members .members-dev ul');
        });
    });
    // Edit: onChange chọn thành viên tester
    $(document).on('change', '#project-edit-staffId_tester', function (e) {
        e.preventDefault();
        $('#edit-project .project-members .members-test ul').html('');
        $('#edit-project .project_select__members-tester .bootstrap-select li.selected').each(function( index ) {
            $(this).clone().appendTo('#edit-project .project-members .members-test ul');
        });
    });

    // Delete contract
    $(document).on('click', '.btn-delete-project', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Project',
            text: 'Bạn muốn xóa dự án?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/project/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click detail project
    $(document).on('click', '.btn-detail-project', function (e) {
        e.preventDefault();
        $('#detail-project-name').html('');
        $('#detail-project-date').html('');
        $('#modal-project-detail .detail-list-member .detail-list-dev ul').html('');
        $('#modal-project-detail .detail-list-member .detail-list-test ul').html('');

        var id = $(this).attr('data-id');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/project/detail',{
            type: 'POST',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    $('#detail-project-name').html(data.name);
                    $('#detail-project-code').html(data.codeProject);
                    $('#detail-project-pm').html(data.fullName);
                    $('#detail-project-date').html(data.startEffectProject + ' - ' + data.endEffectProject);
                    $('#modal-project-detail .detail-list-member .detail-list-dev ul').html(data.staffDev);
                    $('#modal-project-detail .detail-list-member .detail-list-test ul').html(data.staffTest);

                    $('#modal-project-detail').modal('show');
                }
            }
        });
    });
    // Click btn edit project
    $(document).on('click', '.btn-edit-project', function (e) {
        e.preventDefault();
        $('input[name="edit_codeProject"]').val('');
        $('input[name="edit_name"]').val('');
        $('input[name="edit_date_from"]').val('');
        $('input[name="edit_date_to"]').val('');
        $('#project-search-edit-staffId_dev').selectpicker('val', []);
        $('#project-search-edit-staffId_tester').selectpicker('val', []);
        $('#edit-project .project-members .members-dev ul').html('');
        $('#edit-project .project-members .members-test ul').html('');

        var id = $(this).attr('data-id');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/project/edit-index',{
            type: 'POST',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    $('input[name="id_project"]').val(data.id);
                    $('#project-search-edit-staffId_pm').selectpicker('val', data.staffId);
                    $('input[name="edit_codeProject"]').val(data.codeProject);
                    $('input[name="edit_name"]').val(data.name);
                    $('input[name="edit_date_from"]').val(data.startEffectProject);
                    $('input[name="edit_date_to"]').val(data.endEffectProject);
                    $('#project-edit-staffId_dev').selectpicker('val', data.staffDev);
                    $('#project-edit-staffId_tester').selectpicker('val', data.staffTest);
                    $('#edit-project .project-members .members-dev ul').html(data.staffDev_html);
                    $('#edit-project .project-members .members-test ul').html(data.staffTest_html);
                    // $('#detail-project-name').html(data.name);
                    // $('#detail-project-code').html(data.codeProject);
                    // $('#detail-project-pm').html(data.fullName);
                    // $('#detail-project-date').html(data.startEffectProject + ' - ' + data.endEffectProject);
                    // $('#modal-project-detail .detail-list-member .detail-list-dev ul').html(data.staffDev);
                    // $('#modal-project-detail .detail-list-member .detail-list-test ul').html(data.staffTest);

                    $('#edit-project').modal('show');
                }
            }
        });
    });

})(jQuery);