(function ($) {
    $.validator.setDefaults({
        success: "valid",
    });

    //Create Checkin
    $('#form-create-checkin').validate({
        rules:{
            number_rice: {
                min: 1,
            },
        },
        messages: {
            number_rice:
                {
                    min:"Nhập số lớn hơn 0."
                }
        },
    });
    //Edit Checkin
    $('#form-edit-checkin').validate({
        rules:{
            edit_number_rice: {
                digits: true,
            },
            edit_reason: {
                required: true,
            },
        },
        messages: {
            edit_number_rice:
                {
                    digits:"Nhập số nguyên dương."
                },
            edit_reason:
                {
                    required:"Bắt buộc nhập."
                }
        },
    });
    //Update rice Checkin
    $('#form-update-rice-checkin').validate({
        rules:{
            update_number_rice: {
                min: 1,
            },
            update_edit_reason: {
                required: true,
            },
        },
        messages: {
            update_number_rice:
                {
                    min:"Nhập số lớn hơn 0."
                },
            update_edit_reason:
                {
                    required:"Bắt buộc nhập."
                }
        },
    });
    //Create Checkin Hộ
    $('#form-create-ho-checkin').validate({
        rules:{
            reason: {
                required: true,
            },
            staffId: {
                required: true,
            },
            number_rice_h: {
                min: 1,
            },
        },
        messages: {
            reason:
                {
                    required:"Hãy nhập lý do."
                },
            staffId:
                {
                    required:"Hãy chọn nhân viên."
                },
            number_rice_h:
                {
                    min:"Nhập số lớn hơn 0."
                }
        },
    });
    // Approve leave
    $('#form-approve-leave').validate({
        rules:{
            leave_reason_approve: {
                required: true,
            },
        },
        messages: {
            leave_reason_approve:
                {
                    required:"Bắt buộc nhập."
                }
        },
    });
    //Create nghỉ phép
    $('#form-create-leave').validate({
        rules:{
            leave_type: {
                required: true,
            },
            date_from: {
                required: true,
            },
            date_to: {
                greaterThan: '#date_from_leave',
            },
            leave_reason: {
                required: true,
            },
        },
        messages: {
            leave_type:
                {
                    required:"Hãy chọn loại."
                },
            date_from:
                {
                    required:"Hãy chọn ngày bắt đầu."
                },
            date_to:
                {
                    greaterThan:"Phải lớn hơn ngày bắt đầu."
                },
            leave_reason:
                {
                    required:"Hãy nhập lý do."
                }
        },
    });
    //Edit nghỉ phép
    $('#form-edit-leave').validate({
        rules:{
            leave_type_edit: {
                required: true,
            },
            date_from_edit: {
                required: true,
            },
            date_to_edit: {
                greaterThan: '#date_from_leave_edit',
            },
            leave_reason_edit: {
                required: true,
            },
        },
        messages: {
            leave_type_edit:
                {
                    required:"Hãy chọn loại."
                },
            date_from_edit:
                {
                    required:"Hãy chọn ngày bắt đầu."
                },
            date_to_edit:
                {
                    greaterThan:"Phải lớn hơn ngày bắt đầu."
                },
            leave_reason_edit:
                {
                    required:"Hãy nhập lý do."
                }
        },
    });
    //Create user
    $('#form-create-profile').validate({
        rules:{
            fullname: {
                required: true,
            },
            displayName: {
                required: true,
            },
            email: {
                required: true,
                email: true
            },
            mobile: {
                number: true,
                min: 0
            },
            mobileContact: {
                number: true,
                min: 0
            },
            add_role: {
                required: true,
            },
        },
        messages: {
            fullname:
                {
                    required:"Bắt buộc nhập."
                },
            displayName:
                {
                    required:"Bắt buộc nhập."
                },
            email:
                {
                    required:"Bắt buộc nhập.",
                    email:"Không đúng định dạng email.",
                },
            mobile:
                {
                    number:"Hãy nhập số.",
                    min:"phải nhập số không âm.",
                },
            mobileContact:
                {
                    number:"Hãy nhập số.",
                    min:"phải nhập số không âm.",
                },
            add_role:
                {
                    required:"Bắt buộc chọn."
                },
        },
    });
    //Edit info profile
    $('#form-edit-profile').validate({
        rules:{
            displayName: {
                required: true,
            },
            fullName: {
                required: true,
            },
            mobile: {
                number: true,
                min: 0
            },
            mobileContact: {
                number: true,
                min: 0
            },
        },
        messages: {
            displayName:
                {
                    required:"Tên hiển thị bắt buộc nhập."
                },
            fullName:
                {
                    required:"Bắt buộc nhập."
                },
            mobile:
                {
                    number:"Hãy nhập số.",
                    min: "Phải nhập số không âm."
                },
            mobileContact:
                {
                    number:"Hãy nhập số.",
                    min: "Phải nhập số không âm."
                },
        },
    });
    //Create clean
    $('#form-create-clean').validate({
        rules:{
            add_clean_date: {
                required: true,
            },
            staffId: {
                required: true,
            },
        },
        messages: {
            add_clean_date:
                {
                    required:"Bắt buộc chọn."
                },
            staffId:
                {
                    required:"Bắt buộc chọn."
                },
        },
    });
    //Replace clean
    $('#form-edit-clean').validate({
        rules:{
            edit_staffId_replace: 'required',
            edit_staffId_replaced: 'required'
        },
        messages: {
            edit_staffId_replace:  "Bắt buộc nhập.",
            edit_staffId_replaced:  "Bắt buộc nhập."
        },
    });
    //Create papers
    $('#form-create-papers').validate({
        rules:{
            add_name: {
                required: true,
            },
            number: {
                number: true,
                min: 0
            },
        },
        messages: {
            add_name:
                {
                    required:"Bắt buộc nhập."
                },
            number:
                {
                    number:"Hãy nhập số.",
                    min:"Số phải lớn hơn 0.",
                },
        },
    });
    //Edit papers
    $('#form-edit-papers').validate({
        rules:{
            edit_name: {
                required: true,
            },
            edit_number: {
                number: true,
                min: 0
            },
        },
        messages: {
            edit_name:
                {
                    required:"Bắt buộc nhập."
                },
            edit_number:
                {
                    number:"Hãy nhập số.",
                    min:"Số phải lớn hơn 0.",
                },
        },
    });
    //Create contract
    $('#form-create-contract').validate({
        rules:{
            add_staffId: 'required',
            date_contract: 'required',
            type: 'required',
            // position: 'required',
            salary: {
                required: true,
                number: true,
            },
            // duration: 'required',
            date_review: 'required',
            date_end: 'required',
        },
        messages: {
            add_staffId:  "Bắt buộc chọn.",
            date_contract: "Bắt buộc chọn.",
            type: "Bắt buộc chọn.",
            // position: "Bắt buộc nhập.",
            salary: {
                required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
            },
            // duration: "Bắt buộc nhập.",
            date_review: "Bắt buộc chọn.",
            date_end: "Bắt buộc chọn.",
        },
    });
    //Edit contract
    $('#form-edit-contract').validate({
        rules:{
            edit_staffId: 'required',
            edit_date_contract: 'required',
            edit_type: 'required',
            edit_salary: {
                required: true,
                number: true,
            },
            edit_date_review: 'required',
            edit_date_end: 'required',
        },
        messages: {
            edit_staffId:  "Bắt buộc chọn.",
            edit_date_contract: "Bắt buộc chọn.",
            edit_type: "Bắt buộc chọn.",
            edit_salary: {
                required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
            },
            edit_date_review: "Bắt buộc chọn.",
            edit_date_end: "Bắt buộc chọn.",
        },
    });
    //Create notification
    $('#form-create-noti').validate({
        rules:{
            title: 'required',
            contents: 'required',
            type: 'required',
            staffId: 'required',
        },
        messages: {
            title:  "Bắt buộc nhập.",
            contents: "Bắt buộc nhập.",
            type: "Bắt buộc chọn.",
            staffId: "Bắt buộc chọn.",
        },
    });
    //Create project
    $('#form-create-project').validate({
        rules:{
            name: 'required',
            codeProject: 'required',
            date_from: 'required',
            date_to: {
                required: true,
                greaterThan: "#date-from-add-project"
            },
            staffId_dev: 'required',
            staffId_tester: 'required',
        },
        messages: {
            name:  "Bắt buộc nhập.",
            codeProject:  "Bắt buộc nhập.",
            date_from: "Bắt buộc nhập.",
            date_to: {
                required: "Bắt buộc chọn.",
                greaterThan:"Phải lớn hơn ngày bắt đầu."
            },
            staffId_dev: "Bắt buộc chọn.",
            staffId_tester: "Bắt buộc chọn.",
        },
    });
    //Edit project
    $('#form-edit-project').validate({
        rules:{
            edit_name: 'required',
            edit_codeProject: 'required',
            edit_date_from: 'required',
            edit_date_to: {
                required: true,
                greaterThan: "#date-from-edit-project"
            },
            edit_staffId_dev: 'required',
            edit_staffId_tester: 'required',
        },
        messages: {
            edit_name:  "Bắt buộc nhập.",
            edit_codeProject:  "Bắt buộc nhập.",
            edit_date_from: "Bắt buộc nhập.",
            edit_date_to: {
                required: "Bắt buộc chọn.",
                greaterThan:"Phải lớn hơn ngày bắt đầu."
            },
            edit_staffId_dev: "Bắt buộc chọn.",
            edit_staffId_tester: "Bắt buộc chọn.",
        },
    });
    //Create parts
    $('#form-create-parts').validate({
        rules:{
            name: 'required',
            staffId_chief: 'required',
            staffId_employee: 'required',
        },
        messages: {
            name:  "Bắt buộc nhập.",
            staffId_chief: "Bắt buộc chọn.",
            staffId_employee: "Bắt buộc chọn.",
        },
    });
    //Edit parts
    $('#form-edit-parts').validate({
        rules:{
            edit_name: 'required',
            edit_staffId_chief: 'required',
            edit_staffId_employee: 'required',
        },
        messages: {
            edit_name:  "Bắt buộc nhập.",
            edit_staffId_chief: "Bắt buộc chọn.",
            edit_staffId_employee: "Bắt buộc chọn.",
        },
    });
    //Create menu
    $('#form-create-menu').validate({
        rules:{
            add_menu_date: 'required',
            add_menu_content: 'required',
        },
        messages: {
            add_menu_date:  "Bắt buộc chọn.",
            add_menu_content: "Bắt buộc nhập.",
        },
    });
    //Edit menu
    $('#form-edit-menu').validate({
        rules:{
            edit_menu_date: 'required',
            edit_menu_content: 'required',
        },
        messages: {
            edit_menu_date:  "Bắt buộc chọn.",
            edit_menu_content: "Bắt buộc nhập.",
        },
    });

    //Comment menu
    $('#form-comment-menu').validate({
        rules:{
            content_comment: {
                required: true,
            },
        },
        messages: {
            content_comment:  {
                required: "Bắt buộc nhập.",
            },
        },
    });


    //Create salary review
    $('#form-create-salary-review').validate({
        rules:{
            leaderId: 'required',
            salary: {
                // required: true,
                number: true,
            },
            review_file:{
                extension: "xls|csv|xlsx"
            },
        },
        messages: {
            leaderId: "Bắt buộc chọn.",
            salary: {
                // required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
            },
            review_file:{
                extension: "File chưa đúng định dạng excel (xls, csv, xlsx)."
            }
        },
    });
    //Edit salary review
    $('#form-edit-salary-review').validate({
        rules:{
            edit_leaderId: 'required',
            edit_salary: {
                // required: true,
                number: true,
            },
        },
        messages: {
            edit_leaderId: "Bắt buộc chọn.",
            edit_salary: {
                // required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
            },
        },
    });
    //Approve salary review
    $('#form-approve-salary-review').validate({
        rules:{
            comment_approve: 'required',
            salary_approve: {
                required: true,
                number: true,
            },
        },
        messages: {
            comment_approve:  "Bắt buộc chọn.",
            salary_approve:  {
                required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
            },
        },
    });
    //Create leave mode
    $('#form-create-leave-mode').validate({
        rules:{
            staffId: 'required',
            type: 'required',
            date_from: 'required',
            date_to: {
                required: true,
                greaterThan: "#date_from_leave_mode"
            },
            reason: 'required',
        },
        messages: {
            staffId: "Bắt buộc chọn.",
            type: "Bắt buộc chọn.",
            date_from: "Bắt buộc chọn.",
            date_to: {
                required: "Bắt buộc chọn.",
                greaterThan: "Phải lớn hơn ngày bắt đầu."
            },
            reason: "Bắt buộc nhập.",
        },
    });
    //Create report
    $('#form-create-report').validate({
        rules:{
            week: 'required',
            month: 'required',
            project: 'required',
            add_staffId: 'required',
            // plan: 'required',
            // result: 'required',
            report_file:{
                extension: "xls|csv|xlsx"
            },
        },
        messages: {
            week: "Bắt buộc chọn.",
            month: "Bắt buộc chọn.",
            project: "Bắt buộc chọn.",
            add_staffId: "Bắt buộc chọn.",
            // plan: "Bắt buộc nhập.",
            // result: "Bắt buộc nhập.",
            report_file:{
                extension: "File chưa đúng định dạng (xls, csv, xlsx)."
            }
        },
    });
    //Comment report
    $('#form-approve-report').validate({
        rules:{
            comment_approve_report: 'required',
        },
        messages: {
            comment_approve_report: "Bắt buộc nhập.",
        },
    });
    //Create payment
    $('#form-create-payment').validate({
        rules:{
            date: 'required',
            money: {
                required: true,
                number: true,
            },
            reason: 'required',
        },
        messages: {
            date: "Bắt buộc chọn.",
            money: {
                required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
            },
            reason: "Bắt buộc nhập.",
        },
    });
    //Fines late
    $('#form-fines-late').validate({
        rules:{
            money: {
                required: true,
                number: true,
                // min: 69999,
            },
        },
        messages: {
            money: {
                required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
                // min: "Nhỏ nhất là 70,0000đ.",
            },
        },
    });
    //Punish late
    $('#form-punish-late').validate({
        rules:{
            staffId: 'required',
            date: 'required',
            money_punish: {
                required: true,
                number: true,
            }
        },
        messages: {
            staffId: "Bắt buộc chọn.",
            date: "Bắt buộc chọn.",
            money_punish: {
                required: "Bắt buộc nhập.",
                number: "Hãy nhập số & là số dương.",
            }
        },
    });
    //Edit timkeeping
    $('#form-edit-timekeeping').validate({
        rules:{
            status: 'required',
            note: 'required',
            number: {
                required: true,
                number: true,
            }
        },
        messages: {
            status: "Bắt buộc chọn.",
            note: "Bắt buộc nhập.",
            number: {
                required: "Bắt buộc nhập.",
                number: "Hãy nhập số.",
            }
        },
    });
    //Create ticket
    $('#form-create-ticket').validate({
        rules:{
            staffId: 'required',
            ticket: 'required',
        },
        messages: {
            staffId: "Bắt buộc chọn.",
            ticket: "Bắt buộc nhập.",
        },
    });
    //Edit ticket
    $('#form-edit-ticket').validate({
        rules:{
            edit_ticket: 'required',
            status: 'required',
        },
        messages: {
            edit_ticket: "Bắt buộc nhập.",
            status: "Bắt buộc chọn.",
        },
    });
    //Create event
    $('#form-create-event').validate({
        rules:{
            name: 'required',
            date: 'required',
            address: 'required',
            // contents: 'required',
        },
        messages: {
            name: "Bắt buộc nhập.",
            date: "Bắt buộc chọn.",
            address: "Bắt buộc nhập.",
            // contents: "Bắt buộc nhập.",
        },
    });
    //Edit event
    $('#form-edit-event').validate({
        rules:{
            edit_name: 'required',
            edit_date: 'required',
            edit_address: 'required',
            // edit_content: 'required',
        },
        messages: {
            edit_name: "Bắt buộc nhập.",
            edit_date: "Bắt buộc chọn.",
            edit_address: "Bắt buộc nhập.",
            // edit_content: "Bắt buộc nhập.",
        },
    });

    // Form search page leave detail
    $('#form-search-leave-detail').validate({
        rules:{
            dateFrom : 'required',
            dateTo : {
                required:true,
                dateGreaterThan: '#search_date_from_leave_detail'
            },
        },
        messages: {
            dateFrom: 'Bắt buộc chọn.',
            dateTo:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    $.validator.addMethod("dateGreaterThan",
        function(value, element, params) {
            if ($(params).val() <= value)
                return true;
            else
                return false;
            // return $(params).val() <= value;
        }, 'Phải lớn hơn hoặc bằng ngày bắt đầu.');
})(jQuery);
