(function ($) {
    // Create clean
    $('#form-create-clean').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                // $.notify({
                //     message: res.error
                // },{
                //     type: 'meclip',
                //     delay: 3000,
                //     timer: 500,
                //     placement: {
                //         from: 'top',
                //         align: 'right'
                //     },
                // });
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                // $.notify({
                //     message: res.success
                // },{
                //     url: href,
                //     type: 'meclip',
                //     delay: 3000,
                //     timer: 500,
                //     placement: {
                //         from: 'top',
                //         align: 'right'
                //     },
                // });
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Edit clean
    $('#form-edit-clean').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Edit clean punish
    $('#form-edit-clean-punish').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // click button edit clean
    $(document).on('click', '.btn-edit-clean', function (e) {
        e.preventDefault();

        var dateInt = $(this).attr('data-dateInt');
        var date = $(this).attr('data-date');
        var user = $(this).attr('data-user');
        var punish = $(this).attr('data-punish');

        $('input[name="edit_clean_date"]').val(dateInt);
        $('#clean-date-text').html(date);
        $('#clean-replace-employee').html(user);
        $('#clean-replace-punish').html(punish);

        $('#modal-edit-clean').modal('show');
    });

    // Edit clean law
    $('#form-edit-clean-law').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/clean');
                });
            }
        }
    });
    // Change status
    $(document).on('click', '.btn-change-status-clean', function (e) {
        e.preventDefault();
        var date = $(this).attr('data-date');
        var status = $(this).attr('data-status');
        var href = window.location.href;

        Swal.fire({
            title: 'Change status',
            text: 'Bạn muốn thay đổi trạng thái của ngày này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/clean/change-status',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        date: date,
                        status: status,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);