(function ($) {
    // Create contract
    $('#form-create-contract').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/contract');
                });
            }
        }
    });
    // Edit contract
    $('#form-edit-contract').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // click button edit contract
    $(document).on('click', '.btn-edit-contract', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var staffId = $(this).attr('data-staffId');
        var date = $(this).attr('data-date');
        var type = $(this).attr('data-type');
        // var position = $(this).attr('data-position');
        var salary = $(this).attr('data-salary');
        // var duration = $(this).attr('data-duration');
        var date_review = $(this).attr('data-dateReview');
        var date_end = $(this).attr('data-dateEnd');
        if (type == 1){
            $('#c-edit-type-tv').attr('selected', 'selected');
        }
        else if (type == 2){
            $('#c-edit-type-nv').attr('selected', 'selected');
        }

        $('input[name="id_contract"]').val(id);
        $('#edit-contract-employee').html(staffId);
        $('input[name="edit_date_contract"]').val(date);
        // $('input[name="edit_position"]').val(position);
        $('input[name="edit_salary"]').val(salary);
        // $('input[name="edit_duration"]').val(duration);
        $('input[name="edit_date_review"]').val(date_review);
        $('input[name="edit_date_end"]').val(date_end);

        $('#edit-contract').modal('show');
    });
    // onChange search staffId contract
    $(document).on('change', '#contract-search-staffId', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });

    // Delete contract
    $(document).on('click', '.btn-delete-contract', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Contract',
            text: 'Bạn muốn xóa hợp đồng?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/contract/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click detail contract
    $(document).on('click', '.btn-detail-contract', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var staffId = $(this).attr('data-staffId');
        var date = $(this).attr('data-date');
        var type = $(this).attr('data-type');
        // var position = $(this).attr('data-position');
        var salary = $(this).attr('data-salary');
        // var duration = $(this).attr('data-duration');
        var date_review = $(this).attr('data-dateReview');
        var date_end = $(this).attr('data-dateEnd');
        if (type == 1){
            var t = 'Thử việc';
        }else{
            var t = 'Nhân viên';
        }

        $('#detail-contract-name').html(staffId);
        $('#detail-contract-date').html(date);
        $('#detail-contract-type').html(t);
        // $('#detail-contract-position').html(position);
        $('#detail-contract-salary').html(salary);
        // $('#detail-contract-duration').html(duration);
        $('#detail-contract-date_review').html(date_review);
        $('#detail-contract-date_end').html(date_end);

        $('#modal-contract-detail').modal('show');
    });


    $(document).ready(function(){
        $('#contract-add-salary').keyup(function(event){
            // skip for arrow keys
            if(event.which >= 37 && event.which <= 40){
                event.preventDefault();
            }
            var $this = $(this);
            var num = $this.val().replace(/,/gi, "").split("").reverse().join("");
            var num2 = RemoveRougeChar(num.replace(/(.{3})/g,"$1,").split("").reverse().join(""));

            // console.log(num2);
            $this.val(num2);
        });
        $('#contract-edit-salary').keyup(function(event){
            // skip for arrow keys
            if(event.which >= 37 && event.which <= 40){
                event.preventDefault();
            }
            var $this = $(this);
            var num = $this.val().replace(/,/gi, "").split("").reverse().join("");
            var num2 = RemoveRougeChar(num.replace(/(.{3})/g,"$1,").split("").reverse().join(""));

            // console.log(num2);
            $this.val(num2);
        });
    });
    function RemoveRougeChar(convertString){
        if(convertString.substring(0,1) == ","){
            return convertString.substring(1, convertString.length)
        }
        return convertString;
    }

})(jQuery);