(function ($) {
    // onChange search month
    $(document).on('change', '#search-birthday-month', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    $(document).on('click', '.send-birthday-wish', function () {
        var $this = $(this);
        var id = $(this).attr('data-id');

        $('input[name="staffId"]').val(id);
        $('#modal-send-birthday').modal('show');
    });
    $('#form-create-noti-birthday').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
})(jQuery);
