/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');
require('./vendor/jwplayer');
require('./jwplayer');
require('./custom');
require('./general');
require('./event');
require('./login');
require('./ticket');
require('./navbar');
require('./modal');
require('./dropify');
require('./input-tag');
require('./validate');
require('./image-picker');
require('./swiper');
require('./checkin');
require('./datepicker');
require('./selectpicker');
require('./forget-password');
require('./forgot-code');
require('./profile');
require('./checkin-law');
require('./menu');
require('./leave');
require('./clean');
require('./papers');
require('./ckeditor');
require('./contract');
require('./notification');
require('./project');
require('./parts');
require('./salary-review');
require('./leave-mode');
require('./employee-quit');
require('./report');
require('./payment');
require('./system-management');
require('./birthday');
require('./timekeep');
