(function ($) {
    // CLick btn create check required staffId
    $(document).on('click', '.form-btn-create-report', function (e) {
        e.preventDefault();
        var $this = $(this);
        var dev = $('#report-add-staffId').val();
        if($('#form-create-leave').valid() == true) {
            if($('#report-add-staffId').val().length == 0)
            {
                $('.error-report-add-staffId').text('Bắt buộc chọn!');
            }
            else
            {
                $('.error-report-add-staffId').text('');
            }

            if($('#report-add-staffId').val().length > 0)
            {
                $this.closest('form').submit();
            }
        }
    });
    $('.btn-import-file-report').on('click', function (e) {
        e.preventDefault();
        $('#upload-file-report').click();
    });
    // Show name file upload
    $(document).on('change', '#upload-file-report', function (e) {
        e.preventDefault();
        $('.btn-import-file-report').html(e.target.files[0].name);
    });
    // Click detail report
    $(document).on('dblclick', '.detail-report', function (e) {
        e.preventDefault();
        $('#detail-report-img').attr('src', BASE_URL + '/images/default-user.png');
        $('#detail-report-name').html('');
        $('#detail-report-createDate').html('');
        $('#detail-report-status').html('');
        $('#detail-report-date').html('');
        $('#detail-report-project').html('');
        $('#detail-report-plan').html('');
        $('#detail-report-result').html('');
        $('#detail-report-note').html('');
        $('#detail-report-comment').html('');

        var id = $(this).attr('data-id');
        var avatar = $(this).attr('data-avatar');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/report/detail',{
            type: 'POST',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    if(data.status == -1){
                        var status = 'Chưa duyệt';
                    }else{
                        var status = 'Đã duyệt';
                    }
                    if (avatar.length > 0){
                        $('#detail-report-img').attr('src', avatar);
                    }
                    $('#detail-report-name').html(data.name);
                    $('#detail-report-createDate').html(data.createDate);
                    $('#detail-report-status').html(status);
                    $('#detail-report-date').html(data.date);
                    $('#detail-report-project').html(data.project);
                    $('#detail-report-plan').html(data.plan);
                    $('#detail-report-result').html(data.result);
                    $('#detail-report-note').html(data.note);
                    $('#detail-report-comment').html(data.comment);

                    $('#modal-report-detail').modal('show');
                }
            }
        });
    });
    // Click approve report
    $(document).on('click', '.btn-approve-report', function (e) {
        e.preventDefault();
        $('#approve-report-img').attr('src', BASE_URL + '/images/default-user.png');
        $('#approve-report-name').html('');
        $('#approve-report-createDate').html('');
        $('#approve-report-status').html('');
        $('#approve-report-date').html('');
        $('#approve-report-project').html('');
        $('#approve-report-plan').html('');
        $('#approve-report-result').html('');
        $('#approve-report-note').html('');
        $('input[name="id_approve_report"]').val('');

        var id = $(this).attr('data-id');
        var avatar = $(this).attr('data-avatar');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/report/detail',{
            type: 'POST',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    if(data.status == -1){
                        var status = 'Chưa duyệt';
                    }else{
                        var status = 'Đã duyệt';
                    }
                    if (avatar.length > 0){
                        $('#approve-report-img').attr('src', avatar);
                    }
                    $('#approve-report-name').html(data.name);
                    $('#approve-report-createDate').html(data.createDate);
                    $('#approve-report-status').html(status);
                    $('#approve-report-date').html(data.date);
                    $('#approve-report-project').html(data.project);
                    $('#approve-report-plan').html(data.plan);
                    $('#approve-report-result').html(data.result);
                    $('#approve-report-note').html(data.note);
                    $('input[name="id_approve_report"]').val(data.id);

                    $('#modal-report-approve').modal('show');
                }
            }
        });
    });

    // Comment leave
    $('#form-approve-report').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Create leave
    $('#form-create-report').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/report');
                });
            }
        }
    });
    // onChange action
    $(document).on('change', '#search_report-action', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
})(jQuery);