window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.Swiper = require('swiper/dist/js/swiper');
    window.fs = require("./functions");
    require('bootstrap');
    require('jquery-lazy');
    require('waypoints/lib/jquery.waypoints.min');

    require('waypoints/lib/shortcuts/infinite.min');
    require('dropify/dist/js/dropify.min');
    require('bootstrap4-tagsinput-douglasanpa/tagsinput');
    require('jquery-validation/dist/jquery.validate.min');
    require('jquery-validation/dist/additional-methods.min');
    require('image-picker');
    require('jquery-form/dist/jquery.form.min');
    require('bootstrap-notify');
    require('jquery.repeater/jquery.repeater.min');
    require('x-editable/test/libs/poshytip/jquery.poshytip');
    require('x-editable/dist/jquery-editable/js/jquery-editable-poshytip');
    require('moment');
    require('jquery-datetimepicker');
    require('bootstrap-select');
    require('jquery-ui-dist/jquery-ui.min');
    require('jquery-ui-dist/jquery-ui');
    require('summernote');
    window.Swal = require('sweetalert2/dist/sweetalert2.all.min');

} catch (e) {}
/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

