(function ($) {
    $('.modal-token-timeout').modal('show');
    $('.modal-create-channel').modal('show');
    $('.modal-create-playlist').modal('show');
    $('.channel__banner').on('click',function () {
        $('.modal-channel-cover').modal('show');
    });
    $('.video__report').on('click',function (e) {
        e.preventDefault();
        $('.modal-report-video').modal('show');
    });
    // $('.modal-playlist-add-video').modal({backdrop: 'static', keyboard: false});
    $('.playlist__addVideo-modal').click(function () {
        $('.modal-playlist-add-video').modal({backdrop: 'static', keyboard: false});
        $('.playlist-search input[type=text]').val('');
        $('.playlist-search-result').empty();
        $('.playlist-search-url input[type=text]').val('');
        $('.playlist-url-result').html('<p>Nếu video tồn tại bạn có thể xem trước ở đây</p>');
    });
})(jQuery);
