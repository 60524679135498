(function ($) {
    // CLick btn create check required staffId
    $(document).on('click', '#form-btn-create-parts', function (e) {
        e.preventDefault();
        var $this = $(this);
        var dev = $('#parts-staffId_employee').val();
        if($('#form-create-parts').valid() == true) {
            if($('#parts-staffId_employee').val().length == 0)
            {
                $('.error-staffId-employee').text('Bắt buộc nhập!');
            }
            else
            {
                $('.error-staffId-employee').text('');
            }

            if($('#parts-staffId_employee').val().length > 0)
            {
                $this.closest('form').submit();
            }
        }
    });
    // CLick btn edit check required staffId
    $(document).on('click', '#form-btn-create-parts', function (e) {
        e.preventDefault();
        var $this = $(this);
        var dev = $('#parts-edit-staffId_employee').val();
        if($('#form-edit-parts').valid() == true) {
            if($('#parts-edit-staffId_employee').val().length == 0)
            {
                $('.error-edit-staffId-employee').text('Bắt buộc nhập!');
            }
            else
            {
                $('.error-edit-staffId-employee').text('');
            }

            if($('#parts-edit-staffId_employee').val().length > 0)
            {
                $this.closest('form').submit();
            }
        }
    });
    // Create parts
    $('#form-create-parts').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/parts');
                });
            }
        }
    });
    // Create: onChange chọn thành viên
    $(document).on('change', '#parts-staffId_employee', function (e) {
        e.preventDefault();
        $('#create-parts .parts-members .members-employee ul').html('');
        $('#create-parts .parts_select__members-employee .bootstrap-select li.selected').each(function( index ) {
            // let id = $(this).attr('data-id');
            // if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
            $(this).clone().appendTo('#create-parts .parts-members .members-employee ul');
            // }
        });
    });

    // Edit: parts
    $('#form-edit-parts').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // click button edit parts
    $(document).on('click', '.btn-edit-parts', function (e) {
        e.preventDefault();
        $('input[name="id_parts"]').val('');
        $('input[name="edit_name"]').val('');
        $('#parts-edit-staffId_chief').selectpicker('val', []);
        $('#parts-edit-staffId_employee').selectpicker('val', []);
        $('#edit-parts .parts-members .members-employee ul').html('');

        var id = $(this).attr('data-id');

        console.log(id);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/parts/edit-index',{
            type: 'GET',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    $('input[name="id_parts"]').val(data.id);
                    $('input[name="edit_name"]').val(data.name);
                    $('#parts-edit-staffId_chief').selectpicker('val', data.staffChief);
                    $('#parts-edit-staffId_employee').selectpicker('val', data.staffEmployee);
                    $('#edit-parts .parts-members .members-employee ul').html(data.staffEmployee_html);

                    $('#edit-parts').modal('show');
                }
            }
        });
    });
    // Edit: onChange chọn thành viên
    $(document).on('change', '#parts-edit-staffId_employee', function (e) {
        e.preventDefault();
        $('#edit-parts .parts-members .members-employee ul').html('');
        $('#edit-parts .parts_select__members-employee .bootstrap-select li.selected').each(function( index ) {
            $(this).clone().appendTo('#edit-parts .parts-members .members-employee ul');
        });
    });

    // Delete contract
    $(document).on('click', '.btn-delete-parts', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Parts',
            text: 'Bạn muốn xóa bộ phận?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/parts/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Click detail parts
    $(document).on('dblclick', '.btn-detail-parts', function (e) {
        e.preventDefault();
        $('#detail-parts-name').html('');
        $('#modal-parts-detail .detail-list-member .detail-list-chief ul').html('');
        $('#modal-parts-detail .detail-list-member .detail-list-employee ul').html('');

        var id = $(this).attr('data-id');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/parts/detail',{
            type: 'GET',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    $('#detail-parts-name').html(data.name);
                    $('#modal-parts-detail .detail-list-member .detail-list-chief ul').html(data.staffChief);
                    $('#modal-parts-detail .detail-list-member .detail-list-employee ul').html(data.staffEmployee);

                    $('#modal-parts-detail').modal('show');
                }
            }
        });
    });

})(jQuery);