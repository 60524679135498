(function ($) {
    // Create ticket
    $('#form-create-ticket').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.error_input){
                $('.footer .overlay').removeClass('overlaing');
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/ticket');
                });
            }
        }
    });

    $(document).on('click', '.btn-edit-ticket', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var user = $(this).attr('data-user');
        var name = $(this).attr('data-name');
        var ticket = $(this).attr('data-ticket');
        var status = $(this).attr('data-status');

        $('input[name="edit_id"]').val(id);
        $('#edit-detail-name').html(name);
        $('input[name="edit_ticket"]').val(ticket);
        $('select[name="status"]').val(status);

        $('#edit-ticket').modal('show');
    });
    // Edit ticket
    $('#form-edit-ticket').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.error_input){
                $('.footer .overlay').removeClass('overlaing');
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/ticket');
                });
            }
        }
    });
    // Click btn xóa
    $(document).on('click', '.btn-ticket-delete', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Ticket',
            text: 'Bạn muốn xóa vé xe này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/ticket/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // onChange limit ticket
    $(document).on('change', '#limit_ticket', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
})(jQuery);
