(function ($) {
    //////////////// Profile ///////

    // Edit Profile
    $('#form-edit-profile').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                // $.notify({
                //     message: res.error
                // },{
                //     type: 'meclip',
                //     delay: 3000,
                //     timer: 500,
                //     placement: {
                //         from: 'top',
                //         align: 'right'
                //     },
                // });
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                // $.notify({
                //     message: res.success
                // },{
                //     url: href,
                //     type: 'meclip',
                //     delay: 3000,
                //     timer: 500,
                //     placement: {
                //         from: 'top',
                //         align: 'right'
                //     },
                // });
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Edit Avatar Profile
    $('#edit_avatar_profile').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // click button edit profile
    $(document).on('click', '.btn-edit-profile', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var displayName = $(this).attr('data-displayName');
        var fullName = $(this).attr('data-fullName');
        var birthday = $(this).attr('data-birthday');
        var address = $(this).attr('data-address');
        var addressContact = $(this).attr('data-addressContact');
        var facebook = $(this).attr('data-facebook');
        var skype = $(this).attr('data-skype');
        var mobile = $(this).attr('data-mobile');
        var mobileContact = $(this).attr('data-mobileContact');
        var startWork = $(this).attr('data-startWork');

        $('input[name="id_user"]').val(id);
        $('input[name="displayName"]').val(displayName);
        $('input[name="fullName"]').val(fullName);
        $('input[name="birthday"]').val(birthday);
        $('input[name="address"]').val(address);
        $('input[name="addressContact"]').val(addressContact);
        $('input[name="facebook"]').val(facebook);
        $('input[name="skype"]').val(skype);
        $('input[name="mobile"]').val(mobile);
        $('input[name="mobileContact"]').val(mobileContact);
        $('input[name="startWork"]').val(startWork);

        $('#edit-info-profile').modal('show');
    });
    // click button edit papers
    $(document).on('click', '.btn-edit-papers', function (e) {
        e.preventDefault();

        // var id = $(this).attr('data-id');
        // var displayName = $(this).attr('data-displayName');
        // var birthday = $(this).attr('data-birthday');
        // var address = $(this).attr('data-address');
        // var addressContact = $(this).attr('data-addressContact');
        // var facebook = $(this).attr('data-facebook');
        // var skype = $(this).attr('data-skype');
        // var mobile = $(this).attr('data-mobile');
        // var mobileContact = $(this).attr('data-mobileContact');
        //
        // $('input[name="id_user"]').val(id);
        // $('input[name="displayName"]').val(displayName);
        // $('input[name="birthday"]').val(birthday);
        // $('input[name="address"]').val(address);
        // $('input[name="addressContact"]').val(addressContact);
        // $('input[name="facebook"]').val(facebook);
        // $('input[name="skype"]').val(skype);
        // $('input[name="mobile"]').val(mobile);
        // $('input[name="mobileContact"]').val(mobileContact);

        $('#update-profile-papers').modal('show');
    });
    // Edit Papers
    $('#form-update-papers-profile').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // onChange edit avatar
    $(document).on('change', '#profile_avatar', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });

    // onChange edit paper
    $(document).on('change', '.status-paper', function (e) {
        e.preventDefault();
        var $this = $(this);
        var href = window.location.href;
        var status = $(this).val();

        Swal.fire({
            title: 'Change status paper?',
            text: 'Bạn muốn thay đổi tình trạng giấy tờ này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $this.closest('form').submit();
            }else{
                if (status == 0){
                    $(this).val(1);
                }else{
                    $(this).val(0);
                }
            }
        });
    });
    $(document).on('change', '.edit-date-paper', function () {
        var $this = $(this);
        var time = $(this).val();
        var time_old = $(this).attr('data-time');

        if (time != time_old)
        {
            $(document).on('focusout', '.edit-date-paper', function () {
                $this.closest('form').submit();
            });
        }
    });
    $('.status-papers-profile').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });

    //////////////// User profile ///////

    // onChange search user profile
    $(document).on('change', '#user-profile-staffId', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange limit list user
    $(document).on('change', '#limit_user-list', function () {
        var $this = $(this);
        $this.closest('form').submit();
    });
    // onChange edit role user
    $(document).on('change', '.change-role-user', function (e) {
        e.preventDefault();
        var $this = $(this);
        var old_role = $this.attr('data-role');
        var id = $this.attr('data-id');
        console.log(old_role);
        Swal.fire({
            title: 'Change role',
            text: 'Bạn muốn thay đổi quyền của người dùng?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $this.closest('form').submit();
            }
            else{
                $('.change-role-user-' + id).val(old_role);
                $('.change-role-user-'+ id +' option[value="'+ old_role +'"]').attr('selected', 'selected');
            }
        });
    });

    // Create user
    $('#form-create-profile').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/list-user');
                });
            }
        }
    });
    // Change role user
    $('.form-list-user-change-role').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    $('#form-btn-create-user').click(function (e){
        e.preventDefault();
        if($('#form-create-profile').valid() == true) {
            $('#form-btn-create-user').attr('disabled', 'disabled');
            $('#form-create-profile').submit();
        }
    });

    $('.btn-import-file').on('click', function (e) {
        e.preventDefault();
        $('#import-file').click();
    });
    $(document).on('change', '#import-file', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('form').submit();
    });
    $('#import_user').ajaxForm({
        beforeSend:function(){
            $('.load').addClass('loading');
            $('.modal.fade').css('z-index', '1');
            $('.modal-backdrop').css('z-index', '1');
        },
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                $('#import-file').val('');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.load').removeClass('loading');
                $('#form-btn-create-user').removeAttr('disabled', 'disabled');
                $('.modal.fade').css('z-index', '1050');
                $('.modal-backdrop').css('z-index', '1040');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    $(document).on('dblclick', '.tr--list-user', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        window.open(BASE_URL + '/profile?id=' + id, '_blank');
    });
})(jQuery);
